import React from 'react'
import styled from 'styled-components'
import { Typography, List } from '@material-ui/core'
import { useContractorDashboardQuery } from '../../apollo/generated'
import Loading from '../ui/Loading'
import PropertyCardJobItem from './PropertyCardJobItem'

const ContractorDashboard = () => {
	const { data, loading, error } = useContractorDashboardQuery()

	if (error) {
		return <Typography>{error.message}</Typography>
	} else if (loading || !data || !data.biddableJobs || !data.ongoingJobs) {
		return <Loading />
	}

	return (
		<Container>
			<Outer>
				<Typography gutterBottom variant="h6">
					Jobs Needing Bids
				</Typography>
				<List>
					{data.biddableJobs.map(j => (
						<PropertyCardJobItem job={j} key={j.id!} />
					))}
				</List>
			</Outer>
			<Outer>
				<Typography gutterBottom variant="h6">
					Won Jobs
				</Typography>
				<List>
					{data.ongoingJobs.map(j => (
						<PropertyCardJobItem job={j} key={j.id!} />
					))}
				</List>
			</Outer>
		</Container>
	)
}

export default ContractorDashboard

const Container = styled.div`
	display: flex;
	flex-direction: row;
	align-items: stretch;
	justify-content: space-evenly;
	flex-wrap: wrap;
	flex: 1;
	padding: 20px;
`

const Outer = styled.div`
	background-color: white;
	color: #333;
	flex: 1;
	max-width: 500px;
	padding: 20px;
	margin: 20px;
	border-radius: 4px;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
	min-height: 400px;
`
