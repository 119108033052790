import * as React from 'react'

import ContractorSidebar from '../../components/contractorSidebar'
import SEO from '../../components/seo/seo'
import ContractorDashboard from '../../components/dashboards/contractor'

const ContractorDashboardPage = () => (
	<ContractorSidebar>
		<SEO title="ContractorDashboard" />
		<ContractorDashboard />
	</ContractorSidebar>
)

export default ContractorDashboardPage
