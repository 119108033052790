import React from 'react'
import styled from 'styled-components'
import { List } from '@material-ui/core'

import SidebarItem from '../ui/sidebar/SidebarItem'

interface Props {
	ongoingJobCount?: number
	biddableJobCount?: number
}

const SidebarJobs = ({ ongoingJobCount, biddableJobCount }: Props) => {
	return (
		<JobsContainer>
			<List>
				<SidebarItem
					name="Jobs Won"
					url="/contractor/jobs"
					badgeCount={ongoingJobCount}
				/>
				<SidebarItem
					name="Bidding Board"
					url="/contractor/bidding-board"
					badgeCount={biddableJobCount}
				/>
				<SidebarItem
					name="Ordered Specs"
					url="/contractor/custom-bids"
				/>
				<SidebarItem
					name="Help Center"
					url="https://helpcenter.fairmarkethome.com"
				/>
			</List>
		</JobsContainer>
	)
}

export default React.memo(SidebarJobs)

const JobsContainer = styled.div`
	padding: 22px 10px;
	display: flex;
	flex-direction: column;
	flex: 1;
	overflow-y: auto;
	overflow-x: hidden;
`
