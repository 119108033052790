import React from 'react'
import '../layout/layout.css'
import StaticDrawerWrapper from '../ui/sidebar/staticDrawerWrapper'

import SidebarJobs from './SidebarJobs'
import HomeSelector from './HomeSelector'
import { useGetContractorDetails } from '../../apollo/queries';

const ContractorSidebar = ({ children }: { children: React.ReactNode }) => {
	const { data: { contractor } = { } } = useGetContractorDetails()

	return (
		<StaticDrawerWrapper
			sideArea={
				() => (
					<>
						<HomeSelector />
						<SidebarJobs
							ongoingJobCount={contractor && contractor.user && contractor.user.ongoingJobCount}
							biddableJobCount={contractor && contractor.user && contractor.user.biddableJobCount}
						/>
					</>
				)
			}
		>
			{children}
		</StaticDrawerWrapper>
	)
}

export default ContractorSidebar
