import React from 'react'
import styled from 'styled-components'
import { useMutation } from '@apollo/react-hooks'
import { Link } from 'gatsby'
import { Button } from '@material-ui/core'
import { GetContractorDetailsDocument as GET_CONTRACTOR_DETAILS } from '../../apollo/generated'
import { SET_CONTRACTOR_AVATAR } from '../../apollo/mutations'
import DrawerTopArea from '../ui/sidebar/DrawerTopArea'
import UploadOverlay from '../ui/UploadOverlay'
import { Query } from "react-apollo"
import FallbackAvatar from '../ui/FallbackAvatar'
import Loading from '../ui/Loading';

const HomeSelector = () => {
	const [SetContractorAvatar] = useMutation(SET_CONTRACTOR_AVATAR)

	return (
		<Query
			query={GET_CONTRACTOR_DETAILS}
			partialRefetch={true}
		>
			{({ loading, data = {} }) => {
				if (loading) return <DrawerTopArea isContractor><Loading relative noText /></DrawerTopArea>
				const { contractor } = data;
				return (
					<DrawerTopArea isContractor>
						<UploadOverlay onUpload={([url]) => SetContractorAvatar({ variables: { url } })}>
							<FallbackAvatar src={contractor && contractor.avatar && contractor.avatar.url} name={contractor && contractor.companyName} size={82} />
						</UploadOverlay>
						<ProfileButton component={Link} to="/contractor/profile/" activeClassName="active">View Profile</ProfileButton>
					</DrawerTopArea>
				)
			}}
		</Query>

	)
}

export default HomeSelector

const ProfileButton = styled(Button)`
	&& {
		color: white;
		text-transform: none;
		margin-top: 4px;
		padding-left: 10px;
		padding-right: 10px;

		&.active {
			font-weight: bold;
		}
	}
`
